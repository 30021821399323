
import { IonPage, IonContent, IonToolbar, alertController, loadingController, IonButton, IonSelect, IonSelectOption, IonTextarea, IonCheckbox, IonLabel, IonInput, IonBackButton } from '@ionic/vue';
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Analytics } from '../../common/analytics';

/**
 * services
 */
import services from "../../services";

export default defineComponent({
  components: { IonPage, IonContent, IonToolbar, IonButton, IonSelect, IonSelectOption, IonTextarea, IonCheckbox, IonLabel, IonInput, IonBackButton },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(){
    const router = useRouter();
    const screenName = "PAGE-AFFILIATE.INQUIRY";
    const analytics = new Analytics;
    const state = reactive({
      form:{
        content:'',
        type:'',
        name:'',
        tel:'',
        email:'',
        company:''
      },
      terms:{
        privacy:false
      }
    });

    const formTerms = computed(() => {
      let isCheck = true;
      if(!state.terms.privacy) {
        isCheck = false;
      }
      return isCheck;
    })

    const presentAlert = async (message: any, callback: any = null) => {
        const alert = await alertController
          .create({
            header: "알림",
            backdropDismiss: false,
            message: message,
            buttons: [
              {
                text: '확인',
                handler: () => {
                  if ( callback ) {
                    callback();
                  }
                },
              },
            ],
          });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      };

    const onSubmit = async () => {
      const loading = await loadingController
      .create({
          cssClass: 'my-custom-class',
          message: '입점&제휴 문의 등록 중입니다.',
      });
      await loading.present();

      const params = {
        content: state.form.content,
        type: state.form.type,
        name: state.form.name,
        tel: state.form.tel,
        email: state.form.email,
        company: state.form.company
      }
      await services.inquiryAdd(params).then(
        response => {
          if(response.status == 200) {
            setTimeout(function() {
              loading.dismiss()
              presentAlert('입점&제휴 문의가 등록 되었습니다',async () =>{
                window.history.length > 1 ? router.go(-1) : router.push('/')
              });
            }, 1000);
          } else {
            setTimeout(function() {
                loading.dismiss()
              }, 1000);
          }
        },
        error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }

          setTimeout(function() {
            loading.dismiss()
            presentAlert(message);
          }, 1000);
        }
      )
    }

     const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push('/')
    }

    onMounted(() => {
      console.log('onMounted');
      analytics.setScreenName(screenName);
    });

    return{ state, onSubmit, formTerms, onClose }
  }
})
